<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="#005f32" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Add policies</span>
          <v-spacer></v-spacer>
        </v-card-title>
         <!-- <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              max-width="70%"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="font-family: kumbhMedium"
                  outlined
                  block
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                >
                  Add Employee Document
                </v-btn>
              </template> -->
              <v-card elevation="0">
                <v-form v-model="addcat" ref="addcat">
                  <v-card-title>
                    <span class="headline">Add Document </span>
                  </v-card-title>
                  <v-card-text>
                    <v-layout wrap>
                      <v-flex xs12 align-self-center text-left pt-5>
                        <v-text-field
                          v-model="title"
                          label="Title"
                          :rules="[rules.required]"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-flex>
                   
                      <v-flex xs12 pt-6>
                        <v-icon
                          x-large
                          color="#408BFF"
                          @click="$refs.files.click()"
                          >mdi-cloud-upload</v-icon
                        >
                      </v-flex>
                      <v-flex xs12 pb-5>
                        <span class="con4">upload Document</span>
                        &nbsp;
                        <input
                          v-show="false"
                          id="file1"
                          ref="files"
                          type="file"
                          multiple
                          @change="browseCover"
                        />
                      </v-flex>
                      <v-flex xs12 py-3 text-left v-for="(item, i) in documentFile1" :key="i">
                        <!-- <v-chips> -->
                          <v-chip
                            @click="documentFile1"
                            close
                            dark
                            color="green"
                            style="margin-right: 6px;margin-b"
                          >
                            {{ item.name }}
                          </v-chip>
                        <!-- </v-chips> -->
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogclose()">
                      Close
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!addcat"
                      @click="addDocument()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            <!-- </v-dialog> -->

        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      pageCount: 1,
      title:null,
      appLoading: false,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      radios: null,
      categoryList: [],
      assetCategoryId: null,
      name: "",
      assetType: null,
      typeList: ["Consumable", "Nonconsumable", "Semiconsumable"],
      assignList: ["Department", "Employee"],
      description: null,
      unitPrice: null,
      assignType: null,
      employeeId: null,
      departmentId: null,
      empList: [],
      deptList: [],
      assetList: [],
      quantity: null,
      assetId: null,
      code: null,
      
      fundType: null,
      donorList: [],
      donorName: null,
      donorCountry: null,
      donorEmail: null,
      donorId: null,
      flag: false,
      docsdata: new FormData(),
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      //doc upload
      documentFile1: [],
    };
  },
mounted(){
  this.getPolicyList()
  this.getPolicyListindividual()
},
  methods: {
    validateInput() {
      if (this.flag) {
        if (!this.donorName) {
          this.msg = "Please provide donor name";
          this.showsnackbar = true;
          return;
        } else if (!this.donorCountry && this.fundType == "Foreign Fund") {
          this.msg = "Please provide donor country";
          this.showsnackbar = true;
          return;
        }
      }
      if (this.radios == 2) {
        if (!this.name) {
          this.msg = "Please provide asset name";
          this.showsnackbar = true;
          return;
        } else if (!this.assetCategoryId) {
          this.msg = "Please provide asset category Id";
          this.showsnackbar = true;
          return;
        } else if (!this.assetType) {
          this.msg = "Please provide asset type";
          this.showsnackbar = true;
          return;
        } else if (!this.unitPrice) {
          this.msg = "Please provide unit price";
          this.showsnackbar = true;
          return;
        } else if (!this.quantity) {
          this.msg = "Please provide quantity";
          this.showsnackbar = true;
          return;
        } else if (!this.code) {
          this.msg = "Please provide code";
          this.showsnackbar = true;
          return;
        } else {
          this.addAsset();
        }

        // else if (!this.assignType) {
        //   this.msg = "Please provide assign type";
        //   this.showsnackbar = true;
        //   return;
        // } else if (this.assignType == "Department" && !this.departmentId) {
        //   this.msg = "Please choose a department";
        //   this.showsnackbar = true;
        //   return;
        // } else if (this.assignType == "Employee" && !this.employeeId) {
        //   this.msg = "Please choose an employee";
        //   this.showsnackbar = true;
        //   return;
        // }
      } else if (this.radios == 1) {
        if (!this.assetId) {
          this.msg = "Please choose an asset";
          this.showsnackbar = true;
          return;
        } else if (!this.unitPrice) {
          this.msg = "Please provide unit price";
          this.showsnackbar = true;
          return;
        } else if (!this.quantity) {
          this.msg = "Please provide quantity";
          this.showsnackbar = true;
          return;
        } else {
          this.addAsset();
        }

        // else if (!this.assignType) {
        //   this.msg = "Please provide assign type";
        //   this.showsnackbar = true;
        //   return;
        // } else if (this.assignType == "Department" && !this.departmentId) {
        //   this.msg = "Please choose a department";
        //   this.showsnackbar = true;
        //   return;
        // } else if (this.assignType == "Employee" && !this.employeeId) {
        //   this.msg = "Please choose an employee";
        //   this.showsnackbar = true;
        //   return;
        // }
      }
    },
    addAsset() {
      // var data = {};
      // data["name"] = this.name;
      this.appLoading = true;
      axios({
        url: "/asset/add",
        method: "POST",
        data: {
          assetId: this.assetId,
          assetCategoryId: this.assetCategoryId,
          name: this.name,
          assetType: this.assetType,
          description: this.description,
          unitPrice: this.unitPrice,
          quantity: this.quantity,
          code: this.code,
          fundType: this.fundType,
          donorId: this.donorId,
          donorName: this.donorName,
          donorCountry: this.donorCountry,
          donorEmail: this.donorEmail,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;

            this.$router.push("/Assets/Inventory");
            this.clearOff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    clearOff() {
      if (this.radios == 1) {
        this.name = "";
        this.assetType = null;
        this.description = null;
        this.assetCategoryId = null;
      }
      if (this.radios == 2) {
        this.assetId = null;
        this.code = null;
      }
      this.fundType = null;
      this.donorName = null;
      this.donorEmail = null;
      this.donorCountry = null;
      this.donorId = null;
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/assetCategory/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

 getPolicyListindividual() {
      this.appLoading = true;
      axios({
        url: "/policy/get",
        method: "GET",
        params: {
          id: "63906cce49271515b8814a8f",
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.data = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
 getPolicyList() {
      this.appLoading = true;
      axios({
        url: "/policy/getlist",
        method: "GET",
        params: {
          id: this.$route.query.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.data = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addPolicy() {
      var data = {};
      data["name"] = this.name;
      axios({
        url: "/employee/award/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          //  this.uploadFile()
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
     browseCover(event) {
      var got = event.target.files;
      if (got.length < 5) {
        for (let i = 0; i < got.length; i++) {
          this.documentFile1.push(got[i]);
        }
        console.log("file isss", this.documentFile1);
      } else {
        this.msg = "Maximum 3 can be uploaded";
        this.showSnackBar = true;
      }
    },
    uploadFile() {
      var data = {};
      data["name"] = this.file1;
      axios({
        url: "/employee/award/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
           this.uploadImg()
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },




     addDocument() {
      if (this.documentFile1.length > 0) {
        this.appLoading = true;
        axios({
          url: "/policy/add",
          method: "post",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            name: this.title,
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.documentID = response.data.data._id;
            console.log("documentID",this.documentID);
            console.log("documentfile",this.documentFile1)
            if (this.documentID) {
              this.docsupload();
            } else {
              alert("document updated");
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      } else {
        this.showsnackbar = true;
        this.msg = "Please Select Document ";
      }
    },
    docsupload() {
      for (var i = 0; i < this.documentFile1.length; i++) {
        this.docsdata.append("document", this.documentFile1[i]);
      }
      this.docsdata.append("id", this.documentID);

      this.appLoading = true;
      axios({
        url: "/upload/policydocument",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: this.docsdata,
      })
        .then((response) => {
          this.appLoading = false;
          this.dialog = false;
          this.documentFile1 = [];
          this.docsdata = new FormData();
          (this.title = null),
            (this.showsnackbar = true);
          this.getData();
          this.msg = response.data.msg;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
